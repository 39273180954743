import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    relative
    h-full
    min-h-[calc(theme('height.80')+theme('height.10'))]
    w-full
    min-w-[calc(theme('height.40')+theme('height[2.5]'))]
    border
    border-gray-100
    bg-white
    lg:min-h-[theme('height.96')]
  `,
  slots: {
    picture: `
      group
      relative
      flex
      aspect-square
      w-full
      overflow-hidden
      [&_img]:h-full
      [&_img]:w-full
      [&_img]:object-cover
      [&_img]:transition-transform
      [&_img]:duration-150
      [&_img]:ease-in
    `,
    meta: `
      pointer-events-none
      absolute
      inset-x-0
      top-2
      z-10
      mx-auto
      w-[calc(theme('width.full')-theme('width.4'))]
      [&_button]:pointer-events-auto
    `,
    stripe: `
      absolute
      bottom-0
      w-full
      bg-red-600
      px-2
      py-1
    `,
    info: `
      grow
      px-2
    `,
    price: `
      border-b-4
      border-b-green-600
      px-2
      py-1
      [&_span]:leading-none
    `,
  },
  variants: {
    offer: {
      true: {
        price: `
          border-b-red-600
          bg-yellow-400
        `,
      },
    },
    outlet: {
      true: {
        stripe: `
          bg-black
          text-green-600
        `,
        price: `
          border-b-green-600
          bg-black
        `,
      },
    },
    soldOut: {
      true: {
        price: `
          border-b-gray-600
          bg-gray-100
          py-2
          text-sm
          font-bold
          text-gray-600
          lg:text-lg
        `,
      },
    },
  },
});
